<script setup>
import moment from 'moment/moment';
import { getCurrentInstance} from 'vue';
import {compClass} from '@/libraries/utils.js';
import { useNavigationStore } from '@/stores/navigation'
import {ModuleObject} from '@/dataObjects/area3relations/ModuleObject.js';
import ArrowRight from '@/Pages/sharedComponents/icons/ArrowRight.vue';
import Lock from '@/Pages/sharedComponents/icons/Lock.vue';
import ProgressCircle from '@/Pages/sharedComponents/icons/ProgressCircle.vue';
import MicroModal from 'micromodal';
import Clock from '@/Pages/sharedComponents/icons/Clock.vue';
import { ModuleTypes } from '@/dataObjects/frameworkObjects/ModuleTypes';
import { ALWAYS_OPEN, UNLIMITED_OPENING_MONTHS } from '@/config/generalConfig';


const navigationStore = useNavigationStore()
// non sono riuscito a importare come variabili la conf di tw
//https://tailwindcss.com/docs/configuration#referencing-in-java-script

const props = defineProps({
	module: ModuleObject,
	childBirthdate: Object,
})
const instance = getCurrentInstance()

const emit = defineEmits(['modelIsNotAccessible'])
//
const progressCircleColor = () => {
	if(!props.module.isAccessible(props.childBirthdate)) {
		return 'blue'
	}
	return 'fuchsia'
}
const classes1 = () => {
	const isAccessible = props.module.isAccessible(props.childBirthdate)
	return {
		'pt-1': isAccessible,
		'pt-2': !isAccessible,
	}
}
const classes2 = () => {
	const isAccessible = props.module.isAccessible(props.childBirthdate)
	return {
		'py-3': isAccessible,
	}
}

const clickH = () => {
    if(!props.module.isAccessible(props.childBirthdate)){
        return
    }
	navigationStore.goToPage(props.module.pages[0]?.id)
}

const notAccessible = () => {
	emit('modelIsNotAccessible')

	MicroModal.show('modal-modelIsNotAccessible')
}

</script>

<template>
	<div :class="compClass(instance)" class="flex basis-full">
		<div class="basis-auto px-5" :class="classes1()">
			<div class="" :class="classes2()">
				<ProgressCircle
					:perc="module.completionPerc()"
					:color=progressCircleColor()
					class="w-10 h-10"
				/>
			</div>
		</div>
		<div class="basis-full">
			<div v-if="module.isAccessible(props.childBirthdate)">
				<div class="relative bg-fuchsia text-white rounded-3xl clickable"
					@click="clickH">
					<div class="pt-5 px-5 flex justify-between">
						<div class="text-xl open-sans font-bold">
							<!-- {{$tg('ordinal.'+module.index)}} {{$tg('step')}} -->
						</div>
						<div >
							<ArrowRight class="h-6 w-6" :color="'yellow'" />
						</div>
					</div>
					<div class="pt-9 px-5">
						<div v-if="module.accessibilityMonths0!=ALWAYS_OPEN"
							class="pb-2 text-xl font-semibold">
							Apribile dai {{module.accessibilityMonths0}} mesi {{ module.accessibilityMonths1!=UNLIMITED_OPENING_MONTHS ? `ai ${module.accessibilityMonths1} mesi e ${module.accessibilityDays1} giorni` : ''}}
						</div>
						<div class="text-[1.6rem] leading-10 font-semibold"
							style="min-height: 5rem;"
							v-html="$tg(module.title)"
						/>
					</div>
					<div class="h-48"
						style="background-position: center bottom;background-size: 100% auto;"
						:style="`background-image:url('/img/area3relations/module${module.index}/bgListItem.svg')`"
					/>
					<!-- <div class="relative px-5 text-base italic font-light bottom-10"
						style="min-height: 5rem;"
						v-html="$tg(module.credits)"
					/>
					<div v-if="module.isCompleted()"
						class="absolute p-3 text-3xl font-poppins font-bold uppercase bg-yellow text-fuchsia rounded-3xl"
						style="bottom: 1rem;left: 50%;transform: translateX(-50%);line-height: 1;">
						{{$tg('completed')}}!
					</div> -->
				</div>
			</div>
			<div v-else
				class="px-7 flex justify-between items-center clickable bg-purplelight text-white rounded-3xl"
				@click="notAccessible">
				<div class="py-4 text-xl font-semibold open-sans">
					{{$tg(module.title)}} - {{module.accessibilityMonths0}} {{$tg('months')}}
				</div>
				<div>
					<Clock :color="'white'" class="w-7" />
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import ModuleListItem from '@/Pages/area3relations/moduleListItem.vue';
import {compClass} from '@/libraries/utils.js'
import {getCurrentInstance, onMounted, ref} from 'vue';
import CustomScrollbar from 'custom-vue-scrollbar'
import 'custom-vue-scrollbar/dist/style.css'
import $ from 'jquery'
import {useArea3relationsStateStore} from '@/stores/area3relationsState.js';
import MicroModal from 'micromodal';
import {usePage} from '@inertiajs/vue3';
import moment from 'moment/moment';
import ModalModelIsNotAccessible from '@/Pages/sharedComponents/modalModelIsNotAccessible.vue'
import WavyTitle from '../sharedComponents/wavyTitle.vue';

const instance = getCurrentInstance()

const areaStore = useArea3relationsStateStore()
// console.debug(areaStore.modules)

onMounted(()=>{
	const safetyOffset = 50
	$('.scrollbar__scroller').height(window.innerHeight - safetyOffset)
})

MicroModal.init()

const user = usePage().props.user
const childBirthdate = moment(user?.child_birthdate)

const modelNotAccessibleAccessibilityMonths0 = ref(null)
</script>

<template>
	<CustomScrollbar :thumb-max-size="500">
		<div class="pt-4 pl-2 pr-8 h-full flex flex-col bg-white" :class="compClass(instance)">
			<div class="pb-14">
				<div class="pl-8">
					<WavyTitle :text="$tg('area3relations.title')" class="py-8 font-poppins text-6xl font-bold text-fuchsia uppercase text-center"/>
					<article
						class="pb-8"
						v-html="$tg('area3relations.description')"
					/>
				</div>
				<div class="basis-full">
					<div v-for="(module, mIndex) in areaStore.modules" :key="mIndex" class="mb-8">
                        <!-- Richiesta eliminazione del report linguaggio 24 mesi da Laura e Claudia, 08/10/2024 -->
						<moduleListItem v-if="mIndex!=0"
							:module="module"
							:childBirthdate="childBirthdate"
							@modelIsNotAccessible="() => modelNotAccessibleAccessibilityMonths0 = module.accessibilityMonths0"
						/>
					</div>
				</div>
			</div>
		</div>
	</CustomScrollbar>

    <ModalModelIsNotAccessible
		:accessibilityMonths0="modelNotAccessibleAccessibilityMonths0"
	/>
</template>

import {BasePageObject} from '@/dataObjects/frameworkObjects/BasePageObject.js';
import {onMounted} from 'vue';
import {useNavigationStore} from '@/stores/navigation.js';
import $ from 'jquery'
import {AreaObject} from '@/dataObjects/frameworkObjects/AreaObject.js';
import { ModuleTypes } from './ModuleTypes';
import {usePage} from '@inertiajs/vue3';
import moment from 'moment';

export class BaseModuleObject {

	constructor(moduleData, props = {}) {
		//i moduli iniziano da 1, non da 0
		this.index = props.index + 1
		this.isFirst = props.isFirst
		this.isLast = props.isLast
		this.questions = []

		this.id = `${props.areaId}${this.index}`
		this.navigationStore = useNavigationStore()
		this.areaStore = props.areaStoreFunction()
		this.areaName = props.areaName
		this.title = moduleData.title || `Modulo ${this.index}`
		this.credits = moduleData.credits || ""
		this.type = moduleData.type || ModuleTypes.TYPE_STANDARD
		this.hasModalEnd = moduleData.hasModalEnd
		this.accessibilityMonths0 = moduleData.accessibilityMonths0
		this.accessibilityMonths1 = moduleData.accessibilityMonths1
		this.accessibilityDays0 = moduleData.accessibilityDays0
		this.accessibilityDays1 = moduleData.accessibilityDays1
		this.tags = moduleData.tags || []

		this._initializePages(moduleData, props)
	}

	_initializePages(moduleData, props) {
		if(this.isInitialized){
			return
		}
		/**
		 * @type {BasePageObject[]}
		 */
		this.pages = moduleData.pages?.map((page, index) => {
			return new BasePageObject(page, this, {
				index: index,
				isFirst: index === 0,
				isLast: index === moduleData.pages.length - 1,
				areaStoreFunction: props.areaStoreFunction,
				areaName: this.areaName
			}) || []
		})

		this.isInitialized = true
	}

	//un modulo e' sbloccato se il precedente e' completato, il primo modulo non e' mai bloccato
	isAccessible() {
		return this.isFirst || this.areaStore.getModule(this.index - 1).isCompleted()
	}
	//un modulo e' completato se tutte pagine accessibili sono completate
	isCompleted() {
		return !this.pages.find(page => page.isAccessible() && !page.isCompleted())
	}
	//un modulo e' vuoto se non ha pagine accessibili
	isEmpty() {
		return !this.firstAccessiblePage()
	}
	firstAccessiblePage() {
		return this.pages.find(page => page.isAccessible())
	}

	/**
	 * vengono considerate solo le pagine con domande, una pagina è completa se ha TUTTE le risposte
	 * @returns {number}
	 */
	completionPerc() {
		const totalPages = this.pages.filter(page => {
			if (page.isAccessible()) {
				return !!page.questions?.length
			} else {
				return false
			}
		})
		const completedPages = totalPages.filter(page => {
			if(page.isAccessible()) {
				return page.isCompleted()
			} else {
				return false
			}
		})
		return 100 * completedPages.length / totalPages.length
	}

	/**
	 * @param questionSupertype {string}
	 * @returns {BaseQuestionObject[]}
	 */
	getQuestionsBySupertype(questionSupertype) {
		const questions = []
		this.pages.forEach(page => {
			const pageSuperType = page.getQuestionsBySupertype(questionSupertype)
			if(pageSuperType && pageSuperType.length){
				questions.push(pageSuperType)
			}
		})
		return questions
	}

	getPageById(pageId) {
		const page = this.pages.find(page => page.id === pageId)
		if(!page) {
			console.warn('pageId not found', pageId)
			return null
		}
		return page
	}

	/**
	 * @param questionSupertype {?string} 'question' | 'video' | 'game'
	 * @returns {number}
	 */
	score(questionSupertype = null) {
		return this.pages.reduce(
			(previousValue, page) => previousValue + page.score(questionSupertype),
			0
		)
	}

	/**
	 * azioni da fare per ogni pagina
	 * 1) controlli di accessibilita' delle pagine e navigazione forzata
	 * 2) registrare per la prima volta tutte le risposte "not_seen"
	 * 3) layout fix
	 *
	 * vanno fatti in onMounted perche l'utente puo arrivare in una pagina in modi
	 * diversi (url diretto, bug di navigazione, navigazione da cronologia)
	 *
	 * @param index {Number}
	 * @returns {BasePageObject}
	 */
	pageSetup(index) {
		//copiato da TimeuseModuleObject
		const user = usePage().props.user
		const childBirthdate = moment(user?.child_birthdate)

		const page = this.pages.find(page => page.index === index)

		//console.debug('pageSetup', index, page, page.questions)

		onMounted(async () => {
			//console.debug('pageSetup onMounted', index)

			//modulo bloccato
			if(!this.isAccessible(childBirthdate)) {
				await this.navigationStore.goHome()
				return
			}

			const accessiblePages = this.pages.filter(page => page.isAccessible())

			/**
			 * 1) controlli di accessibilita' delle pagine e navigazione forzata
			 * se questa non e' accessibile, cerco la prossima accessibile, se non la trovo cerco LA PRIMA accessibile
			 */
			if(!page.isAccessible()) {
				const nextAccessiblePage = accessiblePages.find(maybeNextPage => {
					return maybeNextPage.index > page.index
				})

				if(nextAccessiblePage) {
					await this.navigationStore.goToPage(nextAccessiblePage.id)
				}
				else {
					const firstAccessiblePage = accessiblePages[0]

					if(firstAccessiblePage) {
						await this.navigationStore.goToPage(firstAccessiblePage.id)
					}
					else if(!this.isCompleted()) {
						console.warn('modulo non completato, ma nessuna pagina accessibile')
						await this.navigationStore.goToPage(AreaObject.AREA1_PERSONAL)
					}
				}
				//comunque non faccio altro
				return
			}
			/**
			 * se questa pagina non è completata, controllo che sia la prima da completare, altrimenti vado lì
			 */
			if(!this.isCompleted() && !page.isCompleted()) {
				const firstUncompletedPage = accessiblePages.find(page => !page.isCompleted())

				if(page.id !== firstUncompletedPage.id) {
					await this.navigationStore.goToPage(firstUncompletedPage.id)
					return
				}
			}


			//2) registrare per la prima volta tutte le risposte "not_seen"
			if(page.isNotSeen()) {
				page.setAsSeen()
				await page.save()
			}

			//3) layout fix
			const safetyOffset = 70
			$('.scrollbar__scroller').height($('main').height() - safetyOffset)
		})

		return page
	}

	//debug start
	complete() {
		this.pages.forEach(page => page.complete())
	}
	setAsNotSeen() {
		this.pages.forEach(page => page.setAsNotSeen())
	}
	setAsSeen() {
		this.pages.forEach(page => page.setAsSeen())
	}
	//debug end
}

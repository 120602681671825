import {AreaObject} from '@/dataObjects/frameworkObjects/AreaObject.js';
import {ALWAYS_OPEN, UNLIMITED_OPENING_MONTHS} from '@/config/generalConfig.js';
import {QuestionObject} from '@/dataObjects/area1personal/QuestionObject.js';

const pageWithQuestions = (module) => {
	return {
		id: `${module}.page2`,
		title: `answerTheQuestions`,
		questions: [
			{
				type: QuestionObject.TYPE_LIKERT,
				text: `didYouLikeTheActivity?`,
			},
			{
				type: QuestionObject.TYPE_LIKERT,
				text: `didYourChildLikeTheActivity?`,
			},
			{
				type: QuestionObject.TYPE_LIKERT,
				text: `doYouThinkTheActivityWasHelpful?`,
			},
		],
		hasModalEnd: false,
	}
}

let area, module, page
export const dataArea4activities = {
	id: area = AreaObject.AREA4_ACTIVITIES,
	title: `${area}.title`,
	modules: [
		// cantiamo insieme
		{
			id: module = `${area}.module1`,
			accessibilityMonths0: ALWAYS_OPEN,
			accessibilityMonths1: UNLIMITED_OPENING_MONTHS,
			accessibilityDays0: 0,
			accessibilityDays1: 0,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					text1: `${page}.text1`,
					questions: [],
					hasModalEnd: false,
				},
				pageWithQuestions(module),
			],
			tags: [
				'language',
			],
		},
		// che belli i puzzle
		{
			id: module = `${area}.module2`,
			accessibilityMonths0: ALWAYS_OPEN,
			accessibilityMonths1: UNLIMITED_OPENING_MONTHS,
			accessibilityDays0: 0,
			accessibilityDays1: 0,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					text1: `${page}.text1`,
					questions: [],
					hasModalEnd: false,
				},
				pageWithQuestions(module),
			],
			tags: [
				'language',
				'attention',
				'movement',
			],
		},
		//alleniamo la memoria
		{
			id: module = `${area}.module3`,
			accessibilityMonths0: 30,
			accessibilityMonths1: UNLIMITED_OPENING_MONTHS,
			accessibilityDays0: 0,
			accessibilityDays1: 0,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					text1: `${page}.text1`,
					questions: [],
					hasModalEnd: false,
				},
				pageWithQuestions(module),
			],
			tags: [
				'language',
				'memory',
			],
		},
		//ricordi in famiglia
		{
			id: module = `${area}.module4`,
			accessibilityMonths0: 30,
			accessibilityMonths1: UNLIMITED_OPENING_MONTHS,
			accessibilityDays0: 0,
			accessibilityDays1: 0,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					text1: `${page}.text1`,
					questions: [],
					hasModalEnd: false,
				},
				pageWithQuestions(module),
			],
			tags: [
				'language',
				'memory',
				'emotions',
			],
		},
		//giochiamo con le emozioni
		{
			id: module = `${area}.module5`,
			accessibilityMonths0: ALWAYS_OPEN,
			accessibilityMonths1: UNLIMITED_OPENING_MONTHS,
			accessibilityDays0: 0,
			accessibilityDays1: 0,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					text1: `${page}.text1`,
					questions: [],
					hasModalEnd: false,
				},
				pageWithQuestions(module),
			],
			tags: [
				'language',
				'emotions',
			],
		},
		//una gioielleria in casa
		{
			id: module = `${area}.module6`,
			accessibilityMonths0: ALWAYS_OPEN,
			accessibilityMonths1: UNLIMITED_OPENING_MONTHS,
			accessibilityDays0: 0,
			accessibilityDays1: 0,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					text1: `${page}.text1`,
					questions: [],
					hasModalEnd: false,
				},
				pageWithQuestions(module),
			],
			tags: [
				'language',
				'movement',
			],
		},
		//baule delle esperienze
		{
			id: module = `${area}.module7`,
			accessibilityMonths0: 30,
			accessibilityMonths1: UNLIMITED_OPENING_MONTHS,
			accessibilityDays0: 0,
			accessibilityDays1: 0,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					text1: `${page}.text1`,
					questions: [],
					hasModalEnd: false,
				},
				pageWithQuestions(module),
			],
			tags: [
				'language',
				'memory',
				'emotions',
			],
		},
		//l'arca di noe
		{
			id: module = `${area}.module8`,
			accessibilityMonths0: ALWAYS_OPEN,
			accessibilityMonths1: UNLIMITED_OPENING_MONTHS,
			accessibilityDays0: 0,
			accessibilityDays1: 0,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					text1: `${page}.text1`,
					questions: [],
					hasModalEnd: false,
				},
				pageWithQuestions(module),
			],
			tags: [
				'language',
				'movement',
				'emotions',
			],
		},
		//esploratore di libri
		{
			id: module = `${area}.module9`,
			accessibilityMonths0: ALWAYS_OPEN,
			accessibilityMonths1: UNLIMITED_OPENING_MONTHS,
			accessibilityDays0: 0,
			accessibilityDays1: 0,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					text1: `${page}.text1`,
					questions: [],
					hasModalEnd: false,
				},
				pageWithQuestions(module),
			],
			tags: [
				'language',
				'attention',
			],
		},
		//la pesca delle parole
		{
			id: module = `${area}.module10`,
			accessibilityMonths0: ALWAYS_OPEN,
			accessibilityMonths1: UNLIMITED_OPENING_MONTHS,
			accessibilityDays0: 0,
			accessibilityDays1: 0,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					text1: `${page}.text1`,
					questions: [],
					hasModalEnd: false,
				},
				pageWithQuestions(module),
			],
			tags: [
				'language',
			],
		},
		//tieni il ritmo
		{
			id: module = `${area}.module11`,
			accessibilityMonths0: ALWAYS_OPEN,
			accessibilityMonths1: UNLIMITED_OPENING_MONTHS,
			accessibilityDays0: 0,
			accessibilityDays1: 0,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					text1: `${page}.text1`,
					questions: [],
					hasModalEnd: false,
				},
				pageWithQuestions(module),
			],
			tags: [
				'attention',
				'movement',
			],
		},
		//strega comanda color
		{
			id: module = `${area}.module12`,
			accessibilityMonths0: ALWAYS_OPEN,
			accessibilityMonths1: UNLIMITED_OPENING_MONTHS,
			accessibilityDays0: 0,
			accessibilityDays1: 0,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					text1: `${page}.text1`,
					questions: [],
					hasModalEnd: false,
				},
				pageWithQuestions(module),
			],
			tags: [
				'language',
				'movement',
			],
		},
		//colpisci i mostriciattoli
		{
			id: module = `${area}.module13`,
			accessibilityMonths0: ALWAYS_OPEN,
			accessibilityMonths1: UNLIMITED_OPENING_MONTHS,
			accessibilityDays0: 0,
			accessibilityDays1: 0,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					text1: `${page}.text1`,
					questions: [],
					hasModalEnd: false,
				},
				pageWithQuestions(module),
			],
			tags: [
				'attention',
				'movement',
			],
		},
		//ascolta la parola e salta
		{
			id: module = `${area}.module14`,
			accessibilityMonths0: 30,
			accessibilityMonths1: UNLIMITED_OPENING_MONTHS,
			accessibilityDays0: 0,
			accessibilityDays1: 0,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					text1: `${page}.text1`,
					questions: [],
					hasModalEnd: false,
				},
				pageWithQuestions(module),
			],
			tags: [
				'language',
				'attention',
				'movement',
			],
		},
		//lettura in dialogo
		{
			id: module = `${area}.module15`,
			accessibilityMonths0: ALWAYS_OPEN,
			accessibilityMonths1: UNLIMITED_OPENING_MONTHS,
			accessibilityDays0: 0,
			accessibilityDays1: 0,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					text1: `${page}.text1`,
					questions: [],
					hasModalEnd: false,
				},
				pageWithQuestions(module),
			],
			tags: [
				'language',
				'emotions',
			],
		},
		//recitAZIONE
		{
			id: module = `${area}.module16`,
			accessibilityMonths0: 30,
			accessibilityMonths1: UNLIMITED_OPENING_MONTHS,
			accessibilityDays0: 0,
			accessibilityDays1: 0,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					text1: `${page}.text1`,
					questions: [],
					hasModalEnd: false,
				},
				pageWithQuestions(module),
			],
			tags: [
				'language',
				'movement',
				'emotions',
			],
		},
		//il cesto magico
		{
			id: module = `${area}.module17`,
			accessibilityMonths0: ALWAYS_OPEN,
			accessibilityMonths1: UNLIMITED_OPENING_MONTHS,
			accessibilityDays0: 0,
			accessibilityDays1: 0,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					text1: `${page}.text1`,
					questions: [],
					hasModalEnd: false,
				},
				pageWithQuestions(module),
			],
			tags: [
				'language',
				'attention',
				'movement',
				'memory',
			],
		},
		//oggi musica
		{
			id: module = `${area}.module18`,
			accessibilityMonths0: ALWAYS_OPEN,
			accessibilityMonths1: UNLIMITED_OPENING_MONTHS,
			accessibilityDays0: 0,
			accessibilityDays1: 0,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					text1: `${page}.text1`,
					questions: [],
					hasModalEnd: false,
				},
				pageWithQuestions(module),
			],
			tags: [
				'emotions',
				'attention',
				'movement',
				'memory',
			],
		},
		//dove mi sono nascosto
		{
			id: module = `${area}.module19`,
			accessibilityMonths0: 30,
			accessibilityMonths1: UNLIMITED_OPENING_MONTHS,
			accessibilityDays0: 0,
			accessibilityDays1: 0,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					text1: `${page}.text1`,
					questions: [],
					hasModalEnd: false,
				},
				pageWithQuestions(module),
			],
			tags: [
				'attention',
				'memory',
			],
		},
		//faccio io
		{
			id: module = `${area}.module20`,
			accessibilityMonths0: 30,
			accessibilityMonths1: UNLIMITED_OPENING_MONTHS,
			accessibilityDays0: 0,
			accessibilityDays1: 0,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					text1: `${page}.text1`,
					questions: [],
					hasModalEnd: false,
				},
				pageWithQuestions(module),
			],
			tags: [
				'attention',
				'memory',
				'emotions',
			],
		},
		//la pizza e pronta
		{
			id: module = `${area}.module21`,
			accessibilityMonths0: 30,
			accessibilityMonths1: UNLIMITED_OPENING_MONTHS,
			accessibilityDays0: 0,
			accessibilityDays1: 0,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					text1: `${page}.text1`,
					questions: [],
					hasModalEnd: false,
				},
				pageWithQuestions(module),
			],
			tags: [
				'memory',
				'movement',
			],
		},
		//la scopa diventa un cavallo
		{
			id: module = `${area}.module22`,
			accessibilityMonths0: ALWAYS_OPEN,
			accessibilityMonths1: UNLIMITED_OPENING_MONTHS,
			accessibilityDays0: 0,
			accessibilityDays1: 0,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					text1: `${page}.text1`,
					questions: [],
					hasModalEnd: false,
				},
				pageWithQuestions(module),
			],
			tags: [
				'language',
				'movement',
				'emotions',
			],
		},
		//pallocini emozionati
		{
			id: module = `${area}.module23`,
			accessibilityMonths0: 30,
			accessibilityMonths1: UNLIMITED_OPENING_MONTHS,
			accessibilityDays0: 0,
			accessibilityDays1: 0,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					text1: `${page}.text1`,
					questions: [],
					hasModalEnd: false,
				},
				pageWithQuestions(module),
			],
			tags: [
				'memory',
				'emotions',
				'movement',
			],
		},
		//occhio magico vede
		{
			id: module = `${area}.module24`,
			accessibilityMonths0: 30,
			accessibilityMonths1: UNLIMITED_OPENING_MONTHS,
			accessibilityDays0: 0,
			accessibilityDays1: 0,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					text1: `${page}.text1`,
					questions: [],
					hasModalEnd: false,
				},
				pageWithQuestions(module),
			],
			tags: [
				'language',
				'attention',
				'memory',
			],
		},
	]
}

import {useMainStateStore} from '@/stores/mainState.js';

//https://vuejs.org/guide/reusability/plugins.html#writing-a-plugin
export default {
	install: (app, options) => {
		const gp = app.config.globalProperties
		gp.mainStore = null
		const nickname = 'tg'
		// console.log('install', options, Object.entries(app.config.globalProperties),			usePage().props.value)

		const defaultGender = 'm'

		/**
		 * @param keyOrPage {string|BasePageObject} es. 'likeNotMuch' | 'likeNotMuch.m' | {id: 'area4activities_module2_page1'}
		 * @param endOfKey {string} es. 'instructionsText'
		 * @param counter {number}
		 * @returns {string}
		 */
		const doit = (keyOrPage, endOfKey = '', counter = 0) => {
			if(!keyOrPage) {
				console.error('i18nGender, key is missing')
				return ''
			}
			if(!gp.mainStore) {
				gp.mainStore = useMainStateStore()
			}

			let key
			if(typeof keyOrPage === 'string') {
				//'likeNotMuch'
				key = keyOrPage
			}
			/**
			 * da un oggetto pagina e una chiave parziale (finale) di i18n ottiene la chiave completa di i18n
			 */
			else {
				/**
				 * @type {BasePageObject} es. {id: 'area4activities_module2_page1'}
				 */
				const page = keyOrPage
				/**
				 * @type {string} 'area4activities_module2_page1' >>> 'area4activities.module2.page1'
				 */
				const pageId = page.id.replaceAll('_', '.')

				if(!endOfKey) {
					console.warn('i18nGender, endOfKey not used', endOfKey, page)
					//'area4activities.module2.page1' KO
					key = pageId
				}
				else {
					//'area4activities.module2.page1.instructionsText'
					key = pageId + '.' + endOfKey
				}
			}

			//in caso di valore non tradotto, ovvero chiave non trovata, i18n restituisce una traduzione uguale alla chiave,
			//quindi facciamo seconda chiamata a doit con il suffisso del genere utente, se questo non è presente usiamo defaultGender
			const translateResult = gp.$t(key)

			if(translateResult === key) {
				/**
				 * (counter > 0) significa che ho fatto la seconda chiamata a doit e sto cercando per genere
				 * (es. 'likeNotMuch.m' oppure 'step.m'). In questa chiamata sembra di non aver trovato la traduzione
				 * perché è uguale alla chiave ('likeNotMuch.m' === 'likeNotMuch.m' oppure 'step.m' === 'step.m' ).
				 * Suppongo quindi di averla gia' trovata nella chiamata precedente con la chiave originale ('likeNotMuch' oppure 'step')
				 * e che nella chiamata precedente fosse il particolare caso di chiave e traduzione realmente uguali
				 * (es. in inglese 'step' === 'step'), quindi restituisco la traduzione senza '.m' o f ('likeNotMuch.m' oppure 'step.m').
				 * Per 'likeNotMuch' sarebbe una traduzione mancante, per 'step' sarebbe una traduzione corretta.
				 *
				 * Questo è il caso di un commento molto più lungo del codice. Vediamo inoltre qui che nei commenti
				 * importanti l'uso dell'inglese (lingua cacofonica) risulta dannosa perché la lingua madre è sempre la migliore
				 * per una comunicazione articolata, a prescindere da quanto lo si conosca bene. Si sa che di mamma ce n'è una sola.
				 * Concordo che vedere l'italiano all'interno del codice dia una sensazione di disarmonia, un po' come trovare del
				 * pecorino grattato su uno spaghetto allo scoglio (atto tipicamente anglosassone). È una sensazione che conviene ignorare.
				 */
				if(counter > 0) {
					return translateResult.replace(/\.[mf]$/, '')
				}

				//seconda chiamata con genere
				return doit(`${key}.${gp.mainStore.translateGender || defaultGender}`, endOfKey, ++counter)
			}

			return translateResult
		}

		// inject a globally available $tg() method
		//questo permette ANCHE di usare questo plugin anche in altri plugin
		gp.$tg = doit

		//provide permette di recuperare il plugin nei componenti tramite inject
		//https://vuejs.org/guide/reusability/plugins.html#provide-inject-with-plugins
		app.provide(nickname, {
			translate: (keyOrPage, counter = 0) => gp.$tg(keyOrPage, counter)
		})
	}
}

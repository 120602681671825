import { BaseModuleObject } from "../frameworkObjects/BaseModuleObject";
import moment from 'moment';
import { useNavigationStore } from "@/stores/navigation";

export class ModuleObject extends BaseModuleObject {

	/**
	 * copiato da TimeuseModuleObject
	 * accessibile se il bimbo ha un numero di mesi pari o piu di accessibilityMonths0 e meno mesi di accessibilityMonths1
	 * @param childBirthdate {Moment}
	 * @returns {boolean}
	 */
	isAccessible(childBirthdate){
		if(!childBirthdate) {
			console.error('no childBirthdate', this)
			return false;
		}
		if(this.isFirst) {
			return true
		}
		const date0 = childBirthdate?.clone()
			.add(this.accessibilityMonths0, 'months')
			.add(this.accessibilityDays0, 'days')
			.startOf('day')

		const date1 = childBirthdate?.clone()
			.add(this.accessibilityMonths1, 'months')
			.add(this.accessibilityDays1, 'days')
			.endOf('day')

        let nowCalc = useNavigationStore().fakeNow ? moment(useNavigationStore().fakeNow) : moment()
		return nowCalc.isSameOrAfter(date0) && nowCalc.isBefore(date1)
	}
}

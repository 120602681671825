<script setup>

const props = defineProps({
    text: "",
    class: ""
})

const SPACE_CHAR_TRICK = "~"

function getLetterStyle(char) {

    if (char === SPACE_CHAR_TRICK) {
        return "color: transparent;"
    }
    let minHeight = -0.4
    let maxHeight = 0.4
    let heightMove = (Math.random() * (maxHeight - minHeight) + minHeight).toFixed(2)
    let minRot = -6.0
    let maxRot = 6.0
    let rotation = (Math.random() * (maxRot - minRot) + minRot).toFixed(2)
    return `bottom: ${heightMove}rem; rotate: ${rotation}deg`
}

function getCharacter(char) {
	if(char===" "){
		return SPACE_CHAR_TRICK // trick! Space character was automatically hidden, don't know why. Won't argue.
	} else {
		return char
	}

}

</script>

<template>
    <p class="lowercase" :class="props.class">
        <span class="relative inline-block " :class="{'first-letter:lowercase': index!=0}"
            v-for="(char, index) in props.text.split('')" :key=index :style='getLetterStyle(getCharacter(char))'>
            {{getCharacter(char)}}
        </span>
    </p>
</template>

<style scoped>

</style>


import {browserDt} from '@/libraries/utils.js';

export class BaseQuestionObject {
	//super types !! (used to group types behavior)
	static SUPERTYPE_QUESTION = 'question'
	static SUPERTYPE_VIDEO = 'video'
	static SUPERTYPE_GAME = 'game'
	static SUPERTYPE_PLANNER = 'planner'
	//tipi per spina
	static TYPE_VIDEO = 'video'
	static TYPE_GAME_SLIDER = 'game_slider'
	static TYPE_GAME_CHOICE = 'game_choice'
	static TYPE_GAME_CODE = 'game_code'
	static TYPE_LIKERT = 'likert'
	static TYPE_SLIDER = 'slider'
	static TYPE_BUTTONS = 'buttons'
	static TYPE_INCREASE_DECREASE = 'increase_decrease'
	static TYPE_DAYPART_BUTTONS = 'daypart_buttons'
	static TYPE_DAYPART_BUTTONS_AND_NONE = 'daypart_buttons_and_none'
	static TYPE_DRAG_AND_DROP = 'drag_and_drop'
	static TYPE_LARGE_BUTTONS = 'large_buttons'
	static TYPE_SELECTS_TWO = 'selects_two'
	static TYPE_SELECTS_THREE = 'selects_three'
	static TYPE_SELECTS_SIX = 'selects_six'
	static TYPE_RADIO = 'radio'
	static TYPE_DRAG_AND_ORDER = 'drag_and_order'
	static TYPE_PLANNER_PROMPT = 'planner_prompt'
	static TYPE_TEXTAREA = 'textarea'
	//nuovi tipi per appparola
	static TYPE_TEXT = 'text'
	static TYPE_MONTH_YEAR = 'month_year'
	static TYPE_NUMBER = 'number'
	static TYPE_SELECT = 'select'
	static TYPE_RADIO_THEN_SELECT = 'radio_then_select'
	static TYPE_RADIO_THEN_TEXTAREA = 'radio_then_textarea'
	static TYPE_RADIO_THEN_NUMBER = 'radio_then_number'
	/**
	 * @see App\Models\Answer
	 * todo scegliere piccoli valori negativi non e' stata una buona scelta, perche sono umanamente
	 *  compatibili (ES. likert, increasedecrease
	 */
	static ANSWER_VALUE_DISABLED_QUESTION = '-3' // domanda disabilitata
	static ANSWER_VALUE_NOT_SEEN = '-2'
	static ANSWER_VALUE_SEEN = '-1'
	static ANSWER_VALUE_FAKE_ANSWER = '9999999' //debug
	static ANSWER_VALUE_VIDEO_COMPLETED = '0'
	/**
	 * @see App\IndiciOpponibili\DB
	 */
	static ANSWER_VALUES_GENDER_MALE = 'male'
	static ANSWER_VALUES_GENDER_FEMALE = 'female'
	static ANSWER_VALUES_GENDER_OTHER = 'other'
	static ANSWER_VALUES_GENDER_NONE = 'none'
	static ANSWER_VALUES_TYPE_DRAG_AND_DROP_MUSICAL_INSTRUMENTS_CHILDREN = [
        "none",
		"drums",
		"harmonic",
		"guitar",
		"ukulele",
		"maracasAndRattles",
		"flute",
		"tambourine",
		"drum",
		"xylophone",
		"other",
	]
	static ANSWER_VALUES_TYPE_DRAG_AND_DROP_MUSICAL_INSTRUMENTS_ADULTS = [
        "none",
		"drums",
		"harmonic",
		"guitar",
		"ukulele",
		"bass",
		"harp",
		"clarinet",
		"piano",
		"maracas",
		"transverseFlute",
		"flute",
		"saxophone",
		"viola",
		"trumpet",
		"drum",
		"violin",
		"xylophone",
		"other",
	]
	static ANSWER_VALUES_TYPE_DRAG_AND_DROP_ELECTRONIC_DEVICES = [
        "none",
		"smartphone",
		"pc",
		"notebook",
		"smartTv",
		"tablet"
	]
	static ANSWER_VALUES_TYPE_DRAG_AND_DROP_GAME_TYPES = [
        "none",
		"puppets",
		"householdObjects",
		"toyCars",
		"creativeMaterial",
		"buildings",
		"cognitives",
		"meansOfMovement",
		"disguises",
		"other"
	]
	static ANSWER_VALUES_TYPE_DAYPART_BUTTONS = {
		0: 'mai', 1: 'mattino', 2: 'pomeriggio', 3: 'sera', 4: 'notte',
	}
	static ANSWER_VALUES_TYPE_DAYPART_BUTTONS_AND_NONE = {
		0: 'non desidero un promemoria', 1: 'mattino', 2: 'pomeriggio', 3: 'sera', 4: 'notte',
	}
	static ANSWER_KEYS_TYPE_TWO_SELECTS = ['1', '2']
	static ANSWER_KEYS_TYPE_THREE_SELECTS = ['1', '2', '3']
	static ANSWER_KEYS_TYPE_SIX_SELECTS = ['1', '2', '3', '4', '5', '6', '7']
	static ANSWER_VALUES_TYPE_TWO_SELECTS = {
		0: 'no', 1: 'sì', 2: 'non lo so', 3: 'non ho l\'aria condizionata',
        4: 'non ho problemi', 5: 'mi dimentico', 6: 'tendo a rimandare', 7: 'non penso che sia importante', 8: 'è troppo difficile'
	}
	static ANSWER_VALUES_TYPE_THREE_SELECTS = {
		0: 'no', 1: 'sì', 2: 'non lo so',
	}
	/**
	 * formato delle risposte multiple
	 * lista value10;value20;value40
	 * oggetto key1:value10;key2:value20;key3:value30
	 * oggetto con liste
	 * key1:value10,value20,value30;key2:value10,value20,value30
   */
	static ANSWER_SEPARATOR_VALUES = ';'
	static ANSWER_SEPARATOR_KEYVALUE = ':'
	static ANSWER_SEPARATOR_SUBVALUES = ','

	/**
	 * @param questionData {Object}
	 * @param parent {BasePageObject}
	 * @param props {Object}
	 */
	constructor(questionData, parent, props = {}) {
		this.parent = this.page = parent
		this.index = props.index + 1 //index da 1
		this.isFirst = props.isFirst
		this.isLast = props.isLast
		this.id = `${this.parent.id}_quest${this.index}`
		this.type = questionData.type
		this.supertype = this.getSupertypeByType(questionData.type)
		this.text = questionData.text
		this.text1 = questionData.text1
		this.text2 = questionData.text2
        this.description = questionData.description
		this.disabledIf = questionData.disabledIf
		// errore umano su data.js, text invece di text1
		if(this.type === BaseQuestionObject.TYPE_INCREASE_DECREASE && !this.text1) {
			console.error('BaseQuestionObject, no text1', this.id, questionData)
		}
		//default answer values
		this.answerValue = BaseQuestionObject.ANSWER_VALUE_NOT_SEEN
		this.dbAnswerValue = BaseQuestionObject.ANSWER_VALUE_NOT_SEEN
		//todo dovrebbe diventare answerValue2, anche sul db
		this.answerValueText = null
		this.answerScore = 0
		this.dt = null
		/**
		 * todo trasformare la variabile isDirty in una funzione che controlla l'uguaglianza tra this.dbAnswerValue e this.answerValue
		 *    //poi aggiungervi questo controllo
		 * 		if(!this.isDirty && this.dbAnswerValue !== this.answerValue) {
		 * 			console.log('bad isDirty 1', this.dbAnswerValue , this.answerValue)
		 * 		}
		 * 		else if(this.isDirty && this.dbAnswerValue === this.answerValue) {
		 * 			console.log('bad isDirty 2', this.dbAnswerValue , this.answerValue)
		 * 		}
		 */
		//se true, la risposta non e' stata ancora salvata sul db remoto
		this.isDirty = false

		this.title = questionData.title
		/*
		si puo aggiungere una textarea a una risposta (button/radio) anche con la proprieta "has"
		esempio:
			buttons: {
				1: `si`,
				2: {
					text: `no (specifica)`,
					has: 'textarea'
				},
			},
		*/
		this.buttons = questionData.buttons
		this.exclusiveButtons = questionData.exclusiveButtons
		//TYPE_DRAG_AND_DROP
		this.elements = questionData.elements
		this.values = questionData.values
		if(this.values === BaseQuestionObject.ANSWER_VALUES_TYPE_DRAG_AND_DROP_MUSICAL_INSTRUMENTS_CHILDREN) {
			this.hasMusicalInstrumentsChildren = true
		}
		if(this.values === BaseQuestionObject.ANSWER_VALUES_TYPE_DRAG_AND_DROP_MUSICAL_INSTRUMENTS_ADULTS) {
			this.hasMusicalInstrumentsAdults = true
		}
		else if(this.values === BaseQuestionObject.ANSWER_VALUES_TYPE_DRAG_AND_DROP_ELECTRONIC_DEVICES) {
			this.hasElectronicDevices = true
		}
		else if(this.values === BaseQuestionObject.ANSWER_VALUES_TYPE_DRAG_AND_DROP_GAME_TYPES) {
			this.hasGameTypes = true
		}
		//increase decrease
		this.min = questionData.min
		this.max = questionData.max
		//select
		this.defaultOption = questionData.defaultOption
		this.options = questionData.options
		//TYPE_VIDEO
		this.videoContent = questionData.videoContent

		//special props by type
		if(this.type === BaseQuestionObject.TYPE_SELECTS_TWO) {
			this.subtext1 = this.parent.questionSubtext1
			this.subtext2 = this.parent.questionSubtext2
		}
		else if(this.type === BaseQuestionObject.TYPE_SELECTS_THREE) {
			this.subtext1 = this.parent.questionSubtext1
			this.subtext2 = this.parent.questionSubtext2
			this.subtext3 = this.parent.questionSubtext3
		}
		else if(this.type === BaseQuestionObject.TYPE_SELECTS_SIX) {
			this.subtext1 = this.parent.questionSubtext1
			this.subtext2 = this.parent.questionSubtext2
			this.subtext3 = this.parent.questionSubtext3
			this.subtext4 = this.parent.questionSubtext4
			this.subtext5 = this.parent.questionSubtext5
			this.subtext6 = this.parent.questionSubtext6
			this.subtext7 = this.parent.questionSubtext7
		}

		this.isSelectsType = (
			this.type === BaseQuestionObject.TYPE_SELECTS_TWO ||
			this.type === BaseQuestionObject.TYPE_SELECTS_THREE ||
			this.type === BaseQuestionObject.TYPE_SELECTS_SIX
		)
		this.hasMultipleAnswers = (
			questionData.type === BaseQuestionObject.TYPE_DAYPART_BUTTONS ||
			questionData.type === BaseQuestionObject.TYPE_DAYPART_BUTTONS_AND_NONE ||
			questionData.type === BaseQuestionObject.TYPE_RADIO ||
			questionData.type === BaseQuestionObject.TYPE_DRAG_AND_DROP ||
			this.isSelectsType
		)
		this.hasMultipleSimpleAnswers = (
			questionData.type === BaseQuestionObject.TYPE_DAYPART_BUTTONS ||
			questionData.type === BaseQuestionObject.TYPE_DAYPART_BUTTONS_AND_NONE ||
			questionData.type === BaseQuestionObject.TYPE_RADIO
		)

		this.hasAnswerValueText = (
			questionData.type === BaseQuestionObject.TYPE_RADIO_THEN_TEXTAREA ||
			questionData.type === BaseQuestionObject.TYPE_RADIO_THEN_NUMBER ||
			questionData.type === BaseQuestionObject.TYPE_RADIO_THEN_SELECT
		)
	}

	getSupertypeByType(type) {
		switch(type) {
			case BaseQuestionObject.TYPE_VIDEO: return BaseQuestionObject.SUPERTYPE_VIDEO;
			case BaseQuestionObject.TYPE_GAME_SLIDER : return BaseQuestionObject.SUPERTYPE_GAME;
			case BaseQuestionObject.TYPE_GAME_CHOICE : return BaseQuestionObject.SUPERTYPE_GAME;
			case BaseQuestionObject.TYPE_GAME_CODE : return BaseQuestionObject.SUPERTYPE_GAME;
			case BaseQuestionObject.TYPE_PLANNER_PROMPT : return BaseQuestionObject.SUPERTYPE_PLANNER;
			default: return BaseQuestionObject.SUPERTYPE_QUESTION;
		}
	}

	/**
	 * dati dal db
	 * @param answer {Object}
	 */
	hydrate(answer) {
		this.dbAnswerValue = answer.value
		this.answerValue = answer.value
		this.answerValueText = answer.value_text
		this.dt = answer.browser_dt
		this.answerScore = +answer.achievement?.score
		this.isDirty = false
		//this.id === 'area1personal_module4_page2_quest0' && console.log('BaseQuestionObject', 'hydrate', answer.value, this.answerValue)
	}

	siblings() {
		return this.parent.questions.filter(question => question.index !== this.index)
	}

	/**
	 * domanda con risposta vera, ovvero diversa da ANSWER_VALUE_SEEN e ANSWER_VALUE_NOT_SEEN
	 * @param dbAnswersOnly considera solo le risposte salvate nel db remoto
	 * @returns {Boolean|boolean}
	 */
	isAnswered(dbAnswersOnly = false){
		const answerValue = this._getAnswerValue(dbAnswersOnly)

		if(
			answerValue === BaseQuestionObject.ANSWER_VALUE_SEEN ||
			answerValue === BaseQuestionObject.ANSWER_VALUE_NOT_SEEN
		) {
			return false
		}
		/**
		 * TYPE_SELECTS può avere una risposta non completa
		 */
		if(this.isSelectsType) {
			return this._typeSelect_isAnswerCompleted(answerValue)
		}

		return true
	}

	isDisabled(dbAnswersOnly = false) {
		return this._getAnswerValue(dbAnswersOnly) === BaseQuestionObject.ANSWER_VALUE_DISABLED_QUESTION
	}

	/**
	 * TYPE_SELECTS può avere una risposta parziale, non completa, la funzione conteggia
	 * il numero di risposte valide
	 *
	 * TYPE_SELECTS_TWO   la risposta PARZIALE è valida solo se la chiave è in (1,2)   e se il valore è in (0,1,2,3)
	 * TYPE_SELECTS_THREE la risposta PARZIALE è valida solo se la chiave è in (1,2,3) e se il valore è in (0,1,2)
	 * @param answerValue {String}
	 * @returns {Boolean}
	 * @private
	 */
	_typeSelect_isAnswerCompleted(answerValue) {
		let validAnswers, validValues, validKeys, validValuesNumber

		if(answerValue === BaseQuestionObject.ANSWER_VALUE_SEEN) {
			return true
		}

		if(this.type === BaseQuestionObject.TYPE_SELECTS_TWO) {
			validValuesNumber = 2
			validValues = Object.keys(BaseQuestionObject.ANSWER_VALUES_TYPE_TWO_SELECTS)
			validKeys = BaseQuestionObject.ANSWER_KEYS_TYPE_TWO_SELECTS
		}
		else if(this.type === BaseQuestionObject.TYPE_SELECTS_THREE) {
			validValuesNumber = 3
			validValues = Object.keys(BaseQuestionObject.ANSWER_VALUES_TYPE_THREE_SELECTS)
			validKeys = BaseQuestionObject.ANSWER_KEYS_TYPE_THREE_SELECTS
		}
		else if(this.type === BaseQuestionObject.TYPE_SELECTS_SIX) {
			validValuesNumber = 7
			//ebbene sì, SIX Ha le stesse risposte di TWO
			validValues = Object.keys(BaseQuestionObject.ANSWER_VALUES_TYPE_TWO_SELECTS)
			validKeys = BaseQuestionObject.ANSWER_KEYS_TYPE_SIX_SELECTS
		}

		//Object.keys restituisce un array di NOMI di prorietà, quindi un array di stringhe,
		//quindi checkValidity non ha problemi di confronto con answerValue
		const checkValidity = (key, value) => validKeys.includes(key) && validValues.includes(value)

		//https://stackoverflow.com/a/22482737/3740246
		if(Object(answerValue) === answerValue) {
			validAnswers = []
			//answerValue {key1: value10, key2: value40}
			for(let name in answerValue) {
				if(!answerValue.hasOwnProperty(name)) {
					continue
				}
				if(checkValidity(name, answerValue[name])) {
					//How to create an object property from a variable value in JavaScript?
					//ES6 introduces computed property names
					//https://stackoverflow.com/a/25333702/3740246
					validAnswers.push({
						[name]: answerValue[name]
					})
				}
			}
			return validAnswers
		}
		else {
			//answerValue 'key1:value10;key2:value30'
			validAnswers = answerValue.split(BaseQuestionObject.ANSWER_SEPARATOR_VALUES)
				//keyValueString 'key1:value10'
				.filter(keyValueString => {
					// keyValueArray ['key1','value10']
					const keyValueArray = keyValueString.split(BaseQuestionObject.ANSWER_SEPARATOR_KEYVALUE)

					return checkValidity(keyValueArray[0], keyValueArray[1])
				})
		}

		return validAnswers.length === validValuesNumber
	}

	/**
	 * search by key and value
	 * @param key
	 * @param value
	 * @param dbAnswersOnly
	 * @returns {boolean}
	 */
	hasThisAnswer(key, value, dbAnswersOnly = false) {
		let answerValue = this._getAnswerValue(dbAnswersOnly)

		return !!answerValue.split(BaseQuestionObject.ANSWER_SEPARATOR_VALUES)
			//keyValueString 'key1:value10'
			.find(keyValueString => {
				// keyValueArray ['key1','value10']
				const keyValueArray = keyValueString.split(BaseQuestionObject.ANSWER_SEPARATOR_KEYVALUE)

				return keyValueArray[0] === key && keyValueArray[1] === value
			})
	}

	/**
	 * @param answerValue {string}
	 * @param dbAnswersOnly {boolean}
	 * @returns {boolean}
	 */
	hasThisAnswerValue(answerValue, dbAnswersOnly = false) {
		if(answerValue === undefined || answerValue === null || answerValue === '') {
			return false
		}

		//always strings
		answerValue = answerValue.toString()

		let thisAnswerValue = this._getAnswerValue(dbAnswersOnly)
		/**
		 * multirisposta semplice ('value1;value2;value3')
		 * divido thisAnswerValue in pezzi e vi cerco answerValue
		 */
		if(this.hasMultipleSimpleAnswers) {
			thisAnswerValue = thisAnswerValue
				.split(BaseQuestionObject.ANSWER_SEPARATOR_VALUES)
				.find(value => value === answerValue)
		}

		if(thisAnswerValue === null || thisAnswerValue === undefined) {
			return false
		}

		//always strings
		return thisAnswerValue.toString() === answerValue
	}

	hasThisAnswerKey(answerKey, dbAnswersOnly = false) {
		if(this.type !== BaseQuestionObject.TYPE_DRAG_AND_DROP && !this.isSelectsType) {
			return
		}

		return this._getAnswerValue(dbAnswersOnly)
			.split(BaseQuestionObject.ANSWER_SEPARATOR_VALUES)
			.some(keyValueString => {
				//oldKeyValueString 'key0:value30,value40' >> key0
				return keyValueString.split(BaseQuestionObject.ANSWER_SEPARATOR_KEYVALUE)[0] === answerKey
			})
	}

	isSeen(dbAnswersOnly = false) {
		return this._getAnswerValue(dbAnswersOnly) === BaseQuestionObject.ANSWER_VALUE_SEEN
	}

	isNotSeen(dbAnswersOnly = false) {
		return this._getAnswerValue(dbAnswersOnly) === BaseQuestionObject.ANSWER_VALUE_NOT_SEEN
	}

	/**
	 * @param dbAnswersOnly {boolean} se true, considera solo la risposta salvata sul db remoto
	 * @returns {string}
	 */
	_getAnswerValue(dbAnswersOnly) {
		return dbAnswersOnly && this.isDirty
			? this.dbAnswerValue
			: this.answerValue
	}

	/**
	 * solo se non ho gia' risposto normalmente
	 * @param force
	 */
	setAsSeen(force = false) {
		if(!force && this.isAnswered()) {
			return
		}
		this.setAnswer(BaseQuestionObject.ANSWER_VALUE_SEEN)
	}

	removeAnswer(force = false) {
		this.setAsSeen(force)
		this.setAnswerText()
	}

	disable() {
		this._setAnswer(BaseQuestionObject.ANSWER_VALUE_DISABLED_QUESTION)
		this.setAnswerText()
	}
	setAsNotSeen() {
		this.setAnswer(BaseQuestionObject.ANSWER_VALUE_NOT_SEEN)
	}
	/**
	 * e' stato gia risposto con un valore di risposta escludente?
	 * nota: non gestisce il parametro dbAnswersOnly di isAnswered
	 * @returns {Boolean}
	 */
	isAnsweredWithAnExclusiveValue() {
		return (
			!!this.exclusiveButtons?.length &&
			this.isAnswered()	&&
			!!this.answerValue.split(BaseQuestionObject.ANSWER_SEPARATOR_VALUES).find(
				value => this.isExclusiveAnswerValue(value)
			)
		)
	}

	/**
	 * e' un valore di risposta escludente
	 * @param answerValue
	 * @returns {boolean}
	 */
	isExclusiveAnswerValue(answerValue) {
		return this.exclusiveButtons?.includes(answerValue)
	}

	/**
	 * la questione del dbAnswerOnly qui non si pone perche sto aggiornando
	 * l'instanza locale della domanda, l'aggiornamento del db avviene altrove
	 *
	 * hasMultipleAnswers
	 *   hasMultipleSimpleAnswers: 'value10;value20'
	 *   TYPE_DRAG_AND_DROP: {key1: [value10, value20]}
	 *   TYPE_SELECTS_TWO|TYPE_SELECTS_THREE: {key1: value10}
	 * @param answerValue {String|Object}
	 * @param force {Boolean} ignora la concatenazione multirisposta e sostituisce il valore come nelle domande normali
	 */
	setAnswer(answerValue, force = false) {
		//ignore same answerValue
		if(this.hasThisAnswerValue(answerValue)) {
			return
		}

		//impedisce di aggiungere un nuovo valore di risposta, se e' gia' stato scelto un valore escludente
		if(this.isAnsweredWithAnExclusiveValue()) {
			return
		}

		//multirisposta, imposto this.answerValue come la concatenazione del nuovo ai vecchi valori
		if(
			!force &&
			this.hasMultipleAnswers &&
			answerValue !== BaseQuestionObject.ANSWER_VALUE_SEEN &&
			answerValue !== BaseQuestionObject.ANSWER_VALUE_NOT_SEEN
		) {
			//SEEN e NOT_SEEN sono vecchi valori da sostituire senza concatenare
			//oldAnswerValueArray [oldValue1;oldValue2;oldValue3]
			const oldAnswerValueArray = (
				this.answerValue === BaseQuestionObject.ANSWER_VALUE_SEEN ||
				this.answerValue === BaseQuestionObject.ANSWER_VALUE_NOT_SEEN
			)
				? [] : this.answerValue.split(BaseQuestionObject.ANSWER_SEPARATOR_VALUES)
			/**
			 * multirisposta semplice
			 * 'oldValue1;oldValue2' > 'oldValue1;oldValue2;newValue3'
			 */
			if(this.hasMultipleSimpleAnswers) {
				answerValue = oldAnswerValueArray
					//filtro in base al valore per non avere doppioni, non dovrebbe servire
					.filter(oldValue => oldValue !== answerValue)
					.concat([answerValue])
					.join(BaseQuestionObject.ANSWER_SEPARATOR_VALUES)
			}
			else if(this.type === BaseQuestionObject.TYPE_DRAG_AND_DROP) {
				if(typeof answerValue !== 'object') {
					console.error('bad answerValue', this.id, answerValue)
					return
				}

				//answerValue {key1: [value10, value20]}

				//newKeyValuesArray [key1, [value10, value20]]
				const newKeyValuesArray = Object.entries(answerValue)[0]
				// newKeyString 'key1'
				const newKeyString = newKeyValuesArray[0]
				// newValuesArray [value10, value20]
				// non mi fido dei controlli di frontend, unifico i valori https://stackoverflow.com/a/14438954/3740246
				const newValuesArray = newKeyValuesArray[1].filter((v,i,a) => a.indexOf(v) === i)
				//newValuesString 'value10,value20'
				const newValuesString = newValuesArray.join(BaseQuestionObject.ANSWER_SEPARATOR_SUBVALUES)
				//newKeyValuesString 'key1:value10,value20'
				const newKeyValuesString = newKeyString + BaseQuestionObject.ANSWER_SEPARATOR_KEYVALUE + newValuesString

				//answerValue risultante potrebbe essere 'key0:value30,value40;key1:value10,value20'
				answerValue = oldAnswerValueArray
					//filtro in base alla chiave per non avere doppioni, serve
					.filter(oldKeyValueString => {
						//oldKeyValueString 'key0:value30,value40' >> OK
						//oldKeyValueString 'key1:value30,value40' >> KO
						return oldKeyValueString.split(BaseQuestionObject.ANSWER_SEPARATOR_KEYVALUE)[0] !== newKeyString
					})
					//aggiungo il nuovo valore
					.concat([newKeyValuesString])
					.join(BaseQuestionObject.ANSWER_SEPARATOR_VALUES)
			}
			else if(this.isSelectsType) {
				//qui mi sono incartato, partire da TYPE_DRAG_AND_DROP non e' stata una buona idea
				if(typeof answerValue !== 'object') {
					console.error('bad answerValue', this.id, answerValue)
					return
				}
				//answerValue {key: value10}

				//newKeyValuesArray [key1, value10]
				const newKeyValuesArray = Object.entries(answerValue)[0]
				// newKeyString 'key1'
				const newKeyString = newKeyValuesArray[0]
				// newValuesString 'value10'
				const newValuesString = newKeyValuesArray[1]
				//newKeyValuesString 'key1:value10'
				const newKeyValuesString = newKeyString + BaseQuestionObject.ANSWER_SEPARATOR_KEYVALUE + newValuesString

				//answerValue risultante potrebbe essere 'key0:value30;key1:value40'
				answerValue = oldAnswerValueArray
					//filtro in base alla chiave per non avere doppioni, serve
					.filter(oldKeyValueString => {
						//oldKeyValueString 'key0:value30' >> OK
						//oldKeyValueString 'key1:value40' >> KO
						return oldKeyValueString.split(BaseQuestionObject.ANSWER_SEPARATOR_KEYVALUE)[0] !== newKeyString
					})
					//aggiungo il nuovo valore
					.concat([newKeyValuesString])
					.join(BaseQuestionObject.ANSWER_SEPARATOR_VALUES)
			}
		}

		this._setAnswer(answerValue)
	}

	/**
	 * non uso lo standard '#' per le private perché, Vue usa BaseQuestionObject attraverso Proxy, che quindi
	 * non ha l'accesso all'istanza originale. Poi diciamocelo, tutta 'sta necessità di una funzione
	 * privata non c'è, tranne per il piacere di studiare e fare le cose secondo manuale ;P
	 * @param answerValue
	 * @private
	 */
	_setAnswer(answerValue) {
		let isDirty
		/**
		 * se ho tolto l'ultimo valore (dalla risposta multipla), il valore risultante deve essere
		 * ANSWER_VALUE_SEEN, ovvero la risposta di default di una domanda con cui l'utente ha gia' interagito
		 */
		if(answerValue === undefined || answerValue === null || answerValue === '') {
			answerValue = BaseQuestionObject.ANSWER_VALUE_SEEN
			isDirty = false
		}
		else {
			answerValue = answerValue.toString()
			isDirty = true
		}

		if(this.answerValue !== answerValue) {
			this.answerValue = answerValue
			this.isDirty = isDirty
			this.dt = browserDt()

			if(
				answerValue !== BaseQuestionObject.ANSWER_VALUE_SEEN &&
				answerValue !== BaseQuestionObject.ANSWER_VALUE_DISABLED_QUESTION &&
				answerValue !== BaseQuestionObject.ANSWER_VALUE_NOT_SEEN
			) {
				//funzione dell'ultimo minuto, per ora solo domande della stessa pagina:
				// se questa risposta vale x allora altre domande vengono disattivate,
				// quindi se la risposta cambia in y, devo riattivarle.
				// C'e' un buco di gestione per il salvataggio sul db (removeAnswer con 'force' a true),
				// questo perche' 'disabilitato'  e' un concetto di frontend, mentre il salvataggio
				// su db e' di backend. Per ora non serve andare oltre.
				this.siblings().forEach(question => {
					if(!question.disabledIf) {
						return
					}

					/**
					 * @var disabledIfAswervaluesAre {Array}
					 */
					const aswervaluesThatDisableThisQuestion = question.disabledIf[this.id] || question.disabledIf['quest'+this.index]
					if(aswervaluesThatDisableThisQuestion?.length) {
						const operator = question.disabledIf.operator || '==='

						const values = this.hasMultipleAnswers
							? this.answerValue.split(BaseQuestionObject.ANSWER_SEPARATOR_VALUES)
							: [this.answerValue]

						const mustDisable = values.every(answerValue => {
							if(
								(operator === '===' &&  aswervaluesThatDisableThisQuestion.includes(answerValue)) ||
								(operator === '!==' && !aswervaluesThatDisableThisQuestion.includes(answerValue))
							) {
								return true
							}
							return false
						})

						if(mustDisable) {
							question.disable()
						} else {
							question.removeAnswer(true)
						}
					}
				})
			}
		}
	}

	/**
	 * answerValueText non deve essere una stringa vuota, nel caso e' null
	 * @param answerValueText {?String}
	 */
	setAnswerText(answerValueText= null) {
		this.answerValueText = (answerValueText ? answerValueText.trim() : null) || null
	}

	/**
	 * di una multi risposta elimina la risposta, ricerca per valore
	 * @param answerValue
	 */
	removeAnswerByValue(answerValue) {
		if(!this.hasMultipleSimpleAnswers) {
			return
		}
		/**
		 * 'oldValue1;oldValue2' >> 'oldValue1'
		 */
		const newAnswerValue = this.answerValue.split(BaseQuestionObject.ANSWER_SEPARATOR_VALUES)
			.filter(value => value !== answerValue)
			.join(BaseQuestionObject.ANSWER_SEPARATOR_VALUES)

		this._setAnswer(newAnswerValue)
	}

	toggleAnswer(answerValue) {
		if(this.hasThisAnswerValue(answerValue)) {
			this.removeAnswerByValue(answerValue)
			return false
		}
		else {
			//l'eventuale valore escludente viene gestito col force
			this.setAnswer(answerValue, !!this.exclusiveButtons?.includes(answerValue))
			return true
		}
	}

	//solo TYPE_SELECTS, TYPE_DRAG_AND_DROP
	removeAnswerByKey(answerKey) {
		if(this.type !== BaseQuestionObject.TYPE_DRAG_AND_DROP && !this.isSelectsType) {
			return
		}

		answerKey = answerKey.toString()

		//ignore different answerValue
		if(!this.hasThisAnswerKey(answerKey)) {
			return
		}

		const newAnswerValue = this.answerValue.split(BaseQuestionObject.ANSWER_SEPARATOR_VALUES)
			.filter(keyValueString => {
				//oldKeyValueString 'key0:value30,value40' >> key0
				return keyValueString.split(BaseQuestionObject.ANSWER_SEPARATOR_KEYVALUE)[0] !== answerKey
			})
			.join(BaseQuestionObject.ANSWER_SEPARATOR_VALUES)

		this._setAnswer(newAnswerValue)
	}

	/**
	 * da 'key0:value10,value20;key1:value30,value40'
	 * a [{key0: [value10, value20]}, {key1: [value30, value40]}]
	 */
	answerValuesToObjectFormat() {
		//solo multirisposta, TYPE_DRAG_AND_DROP
		if(this.type !== BaseQuestionObject.TYPE_DRAG_AND_DROP) {
			return false
		}

		return this.answerValue
			// ['key0:value10,value20', 'key1:value30,value40']
			.split(BaseQuestionObject.ANSWER_SEPARATOR_VALUES)
			.map(keyValueString => {
				const keyValueArray = keyValueString.split(BaseQuestionObject.ANSWER_SEPARATOR_KEYVALUE)
				const keyString = keyValueArray[0]
				const valuesString = keyValueArray[1]
				//{key0: [value10, value20]}
				return {
					[keyString]: valuesString.split(BaseQuestionObject.ANSWER_SEPARATOR_SUBVALUES)
				}
			})
	}

	//formato per il salvataggio in db
	answerDbFormat() {
		return {
			total_question_id: this.id,
			area_name          : this.parent.areaName,
			module_id        : this.parent.parent.index,
			page_id          : this.parent.index,
			question_id      : this.index,
			question_type    : this.type,
			value            : this.answerValue,
			value_text       : this.answerValueText,
			browser_dt       : this.dt,
		}
	}

	canBeSavedToDb() {
		if(!this.isDirty) {
			return false
		}

		/**
		 * TYPE_SELECTS può avere una risposta parziale, che NON deve essere salvata in db
		 */
		if(this.isSelectsType) {
			return this._typeSelect_isAnswerCompleted(this.answerValue)
		}

		return true
	}

	/**
	 * I bottoni hanno nelle chiavi il valore delle risposte
	 * Usato da radio_with_textarea
	 * @param answerValue
	 * @returns {boolean}
	 */
	isLastAnswerValue(answerValue) {
		return this.buttons
			? +answerValue === Object.keys(this.buttons).length
			: false
	}

	/*
	TYPE_RADIO_THEN_TEXTAREA, TYPE_RADIO_THEN_NUMBER, TYPE_RADIO_THEN_SELECT
	forniscono una textarea all'ultimo elemento, ma si puo aggiungere
	la textarea/number/select a una risposta (button/radio) anche con la proprieta "has"
	esempio:

		buttons: {
			1: `si`,
			2: {
				text: `no (specifica)`,
				has: 'textarea'
			},
		},
	*/
	/**
	 * @param inputType {string} 'textarea' sviluppato, mancano 'number', 'select', ecc.
	 * @param answerValue {string}
	 * @returns {boolean}
	 */
	answerHas(inputType, answerValue) {
		if(this.buttons) {
			const maybeTextButton = this.buttons[answerValue]

			if(!maybeTextButton) {
				console.error('bad buttons', inputType, answerValue, this)
				return false
			}

			//oggeto, forse has='textarea'
			if(typeof maybeTextButton === 'object' || maybeTextButton instanceof Object) {
				console.log(answerValue, maybeTextButton, maybeTextButton[answerValue]?.has, inputType)
				return maybeTextButton.has === inputType
			}

			//stringa, forse ultima risposta
			if(typeof maybeTextButton === 'string' || maybeTextButton instanceof String) {
				return (
					this.hasAnswerValueText &&
					this.isLastAnswerValue(answerValue)
				)
			}
		}

		return false
	}

	/**
	 * @returns {string|null}
	 */
	lastAnswerValue() {
		if(!this.buttons) {
			return null
		}
		const answerValues = Object.keys(this.buttons)

		return answerValues.find(value => +value === answerValues.length)
	}

	mustConfirmAnswer() {
		//video non hanno bisogno di conferma
		if(
			this.supertype !== BaseQuestionObject.SUPERTYPE_QUESTION &&
			this.supertype !== BaseQuestionObject.SUPERTYPE_GAME &&
			this.supertype !== BaseQuestionObject.SUPERTYPE_PLANNER
		) {
			return false
		}

		return this.isDirty && this.isAnswered()
	}

	//debug
	complete() {
		this.setAnswer(BaseQuestionObject.ANSWER_VALUE_FAKE_ANSWER)
	}

	//todo funzione che restituisce lo stato isAlreadyConfirmed
	//  per disattivare tutta setAnswer, guarda mustConfirmAnswer e questionButtons.vue
}

<script setup>
import ModuleListItem from '@/Pages/area4activities/moduleListItem.vue';
import {compClass} from '@/libraries/utils.js'
import {getCurrentInstance, onMounted, ref} from 'vue';
import CustomScrollbar from 'custom-vue-scrollbar'
import 'custom-vue-scrollbar/dist/style.css'
import $ from 'jquery'
import MicroModal from 'micromodal';
import {usePage} from '@inertiajs/vue3';
import moment from 'moment/moment';
import ModalModelIsNotAccessible from '@/Pages/sharedComponents/modalModelIsNotAccessible.vue'
import WavyTitle from '../sharedComponents/wavyTitle.vue';
import {useArea4activitiesStateStore} from '@/stores/area4activitiesState.js';

const instance = getCurrentInstance()

const areaStore = useArea4activitiesStateStore()
// console.debug(areaStore.modules)

onMounted(()=>{
	const safetyOffset = 50
	$('.scrollbar__scroller').height(window.innerHeight - safetyOffset)
})

MicroModal.init()

const user = usePage().props.user
const childBirthdate = moment(user?.child_birthdate)

const modelNotAccessibleAccessibilityMonths0 = ref(null)
</script>

<template>
	<CustomScrollbar :thumb-max-size="500">
		<div class="pt-4 pl-2 pr-8 h-full flex flex-col bg-white" :class="compClass(instance)">
			<div class="pb-14">
				<div class="pl-8">
					<WavyTitle :text="$tg('area4activities.title')" class="py-8 font-poppins text-6xl font-bold text-fuchsia uppercase text-center"/>
					<article
						class="pb-8"
						v-html="$tg('area4activities.description')"
					/>
				</div>
				<div class="basis-full">
					<div v-for="(module, mIndex) in areaStore.modules" :key="mIndex" class="mb-8">
						<moduleListItem
							:module="module"
							:childBirthdate="childBirthdate"
							@modelIsNotAccessible="() => modelNotAccessibleAccessibilityMonths0 = module.accessibilityMonths0"
						/>
					</div>
				</div>
			</div>
		</div>
	</CustomScrollbar>

	<ModalModelIsNotAccessible
		:accessibilityMonths0="modelNotAccessibleAccessibilityMonths0"
	/>
</template>

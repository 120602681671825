import {AreaObject} from '@/dataObjects/frameworkObjects/AreaObject.js';
import { ALWAYS_OPEN, UNLIMITED_OPENING_MONTHS } from '@/config/generalConfig';

/**
 * areaRelations, modules and pages starts with index 1
 *
 * il testo delle proprieta' come 'title', 'text' oppure i bottoni sono le chiavi dei json delle lingue
 * @see /storage/platformConfig/localization/it.json
 *
 **/
let area, module, page
export const dataArea3relations = {
	id: area = AreaObject.AREA3_RELATIONS,
	title: `${area}.title`,
	modules: [
		{
			id: module = `${area}.module1`, // NOTICE: THIS IS ACTUALLY DEACTIVATED BY THEIR REQUEST (09/10/2024)
            accessibilityMonths0: 25,
            accessibilityMonths1: UNLIMITED_OPENING_MONTHS,
            accessibilityDays0: 0,
            accessibilityDays1: 0,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					questions: [],
					hasModalEnd: false,
				},
			],
            type: 'report'
		},
        {
			id: module = `${area}.module2`,
            accessibilityMonths0: 31,
            accessibilityMonths1: UNLIMITED_OPENING_MONTHS,
            accessibilityDays0: 0,
            accessibilityDays1: 0,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					questions: [],
					hasModalEnd: false,
				},
			],
            type: 'report'
		},
        {
			id: module = `${area}.module3`,
            accessibilityMonths0: 37,
            accessibilityMonths1: UNLIMITED_OPENING_MONTHS,
            accessibilityDays0: 0,
            accessibilityDays1: 0,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					questions: [],
					hasModalEnd: false,
				},
			],
            type: 'report'
		},
        {
			id: module = `${area}.module4`,
            accessibilityMonths0: ALWAYS_OPEN,
            accessibilityMonths1: UNLIMITED_OPENING_MONTHS,
            accessibilityDays0: 0,
            accessibilityDays1: 0,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					questions: [],
					hasModalEnd: false,
				},
			],
            type: 'report'
		},
        {
			id: module = `${area}.module5`,
            accessibilityMonths0: 25,
            accessibilityMonths1: UNLIMITED_OPENING_MONTHS,
            accessibilityDays0: 0,
            accessibilityDays1: 0,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					questions: [],
					hasModalEnd: false,
				},
			],
            type: 'report'
		},
        {
			id: module = `${area}.module6`,
            accessibilityMonths0: 31,
            accessibilityMonths1: UNLIMITED_OPENING_MONTHS,
            accessibilityDays0: 0,
            accessibilityDays1: 0,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					questions: [],
					hasModalEnd: false,
				},
			],
            type: 'report'
		},
        {
			id: module = `${area}.module7`,
            accessibilityMonths0: 37,
            accessibilityMonths1: UNLIMITED_OPENING_MONTHS,
            accessibilityDays0: 0,
            accessibilityDays1: 0,
			title: `${module}.title`,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					questions: [],
					hasModalEnd: false,
				},
			],
            type: 'report'
		},
    ]
}

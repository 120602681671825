<script	setup>
import Modal from '@/Pages/sharedComponents/modal.vue';
import {ref} from 'vue';
import RiveComponent from '@/Pages/sharedComponents/riveComponent.vue';
import { timeout } from '@/libraries/utils';

const props = defineProps({
})
const id = 'yourFamilyPortrait'
const riveInstance = ref(null)

defineExpose({
	playAnimation: (animationName) => {
		if(!riveInstance.value) {
			return
		}
		riveInstance.value.playAnimation(animationName)
	},
	pauseAnimation: (animationName) => {
		if(!riveInstance.value) {
			return
		}
		riveInstance.value.pauseAnimation(animationName)
	},
	trigger: (triggerName) => {
		if(!riveInstance.value) {
			return
		}
		riveInstance.value.trigger(triggerName)
	},
	boolean: (booleanName, booleanValue) => {
		if(!riveInstance.value) {
			return
		}
		riveInstance.value.boolean(booleanName, booleanValue)
	},
	number: (numberName, numberValue) => {
		if(!riveInstance.value) {
			return
		}
		riveInstance.value.number(numberName, numberValue)
	}
})

const emit = defineEmits(['ready'])

const riveProps = {
	artboard: 'main-scene',
	statemachine: 'main-scene-sm',
	src: '/parola-family-v8.2.riv',
}

const forceReload = ref(false)
async function resolveRive () {
    console.debug('[modalYourFamilyPortrait] RiveComponent Loading Completed')
    await timeout(100)
    emit('ready')
}

</script>

<template>
	<Modal :id="id" :fullscreen="true" :bgColor="'blue'">
		<div class="py-6">
			{{ $tg('yourFamilyPortrait') }}
		</div>

		<div :key="forceReload" class="rive h-[40rem]" v-if="riveProps" >
			<Suspense @resolve="resolveRive()">
                <RiveComponent ref="riveInstance"
				:src="riveProps.src"
				:artboard="riveProps.artboard"
				:statemachine="riveProps.statemachine"
				fit="cover"
				alignment="center"
				:autoplay="false"
			    />

                <template #fallback>
	                {{$tg('loading')}}
                </template>
            </Suspense>
		</div>

		<div class="btn w-2/3 my-8 bg-white text-blue"
			data-micromodal-close aria-label="Close modal">
			{{ $tg('continue')}}
		</div>
	</Modal>
</template>

<script setup>
import moment from 'moment/moment';
import { getCurrentInstance} from 'vue';
import {compClass} from '@/libraries/utils.js';
import { useNavigationStore } from '@/stores/navigation'
import {ModuleObject} from '@/dataObjects/area1personal/ModuleObject.js';
import ArrowRight from '@/Pages/sharedComponents/icons/ArrowRight.vue';
import Lock from '@/Pages/sharedComponents/icons/Lock.vue';
import ProgressCircle from '@/Pages/sharedComponents/icons/ProgressCircle.vue';
const navigationStore = useNavigationStore()
// non sono riuscito a importare come variabili la conf di tw
//https://tailwindcss.com/docs/configuration#referencing-in-java-script

const props = defineProps({
	module: ModuleObject,
})
const instance = getCurrentInstance()
//
const progressCircleColor = () => {
	if(!props.module.isAccessible()) {
		return 'blue'
	}
	return 'fuchsia'
}
const classes1 = () => {
	const isAccessible = props.module.isAccessible()
	return {
		'pt-1': isAccessible,
		'pt-2': !isAccessible,
	}
}
const classes2 = () => {
	const isAccessible = props.module.isAccessible()
	return {
		'py-3': isAccessible,
	}
}
</script>

<template>
	<div :class="compClass(instance)" class="flex basis-full">
		<div class="basis-auto px-5" :class="classes1()">
			<div class="" :class="classes2()">
				<ProgressCircle
					:perc="module.completionPerc()"
					:color=progressCircleColor()
					class="w-10 h-10"
				/>
			</div>
		</div>
		<div class="basis-full">
			<div v-if="module.isAccessible()">
				<div class="relative bg-fuchsia text-white rounded-3xl" :class="!module.isEmpty() && 'clickable'"
					@click="() => !module.isEmpty() && navigationStore.goToPage(module.firstAccessiblePage()?.id)">
					<div class="pt-5 px-5 flex justify-between">
						<div class="text-xl open-sans font-bold">
							{{$tg('ordinal.'+module.index)}} {{$tg('step')}}
						</div>
						<div >
							<ArrowRight class="h-6 w-6" :color="'yellow'" />
						</div>
					</div>
					<div class="mt-9 px-5 text-[1.6rem] leading-10 font-semibold"
						:class="module.index === 3 && 'first-letter:lowercase'"
						style="min-height: 5rem;"
						v-html="$tg(module.title)"
					/>
					<div class="h-48"
						style="background-position: center bottom;background-size: 100% auto;"
						:style="`background-image:url('/img/area1personal/module${module.index}/bgListItem.svg')`"
					/>
					<div v-if="module.isCompleted()"
						class="absolute p-3 text-3xl font-poppins font-bold uppercase text-center bg-yellow text-fuchsia rounded-3xl"
						style="bottom: 1rem;left: 50%;transform: translateX(-50%);line-height: 1;">
						{{$tg(module.isEmpty() ? 'unnecessary' : 'completed')}}!
					</div>
				</div>
			</div>
			<div v-else
				class="px-7 flex justify-between items-center unclickable bg-purplelight text-white rounded-3xl">
				<div class="py-4 text-xl font-semibold open-sans"
					:class="module.index === 3 && 'first-letter:lowercase'">
					{{$tg(module.title)}}
				</div>
				<div>
					<Lock :color="'white'" class="w-7" />
				</div>
			</div>
		</div>
	</div>
</template>

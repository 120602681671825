import { ModuleTypes } from '@/dataObjects/frameworkObjects/ModuleTypes.js';
import { AreaObject } from '@/dataObjects/frameworkObjects/AreaObject.js';
import { TimeuseQuestionObject } from '@/dataObjects/area2observation/timeuse/TimeuseQuestionObject.js';
import { QuestionObject } from '@/dataObjects/area2observation/QuestionObject';
import { UNLIMITED_OPENING_MONTHS } from '@/config/generalConfig';

/**
 **/
const timeuseModule = (area, index, accessibility, showCredits=false) => {
	return {
		index: index,
		id: module = `${area}.module${index}`,
		title: `${area}.timeuse.title`,
		type: ModuleTypes.TYPE_TIMEUSE,
		accessibilityMonths0: accessibility.months0,
		accessibilityMonths1: accessibility.months1,
		accessibilityDays0: accessibility.days0,
		accessibilityDays1: accessibility.days1,
		credits: showCredits ? `${area}.timeuse.credits` : '',
		pages: [
			{
				id: page = `${module}.page1`,
				title: `${page}.title`,
				questions: [
					{
						type: TimeuseQuestionObject.TYPE_HIDDEN,
					},
				]
			},
			{
				id: page = `${module}.page2`,
				title1: `${area}.timeuse.page2.title1`,
				title2: `${area}.timeuse.page2.title2`,
				questions: [
					{
						type: TimeuseQuestionObject.TYPE_SELECT_ACTIVITY,
					},
					{
						type: TimeuseQuestionObject.TYPE_DATEPICKER,
					},
					{
						type: QuestionObject.TYPE_RADIO,
						text: `${area}.timeuse.whoWasPresentBesidesYou?`,
						buttons: {
							1: `nobodyElse`,
							2: `yourChild`,
							3: `yourChildSiblings`,
							4: `otherChildFamily`,
							5: `otherChildNoFamily`,
							6: `otherParent`,
							7: `otherAdultsFamily`,
							8: `otherAdultsNoFamily`,
						},
						exclusiveButtons: ['1'],
					},
					{
						type: QuestionObject.TYPE_LARGE_BUTTONS,
						text: `${area}.timeuse.wasChildInvolved?`,
						buttons: {
							1: `involved1`,
							2: `involved2`,
							3: `involved3`,
							4: `involved4`,
							5: `involved5`,
						},
						disabledIf: {
							quest3: ['2'],
							operator: '!=='
						},
					},
				],
			},
			{
				id: page = `${module}.page3`,
				title: `${page}.title`,
			},
			{
				id: page = `${module}.page4`,
				title: `${area}.timeuse.answerTheQuestions`,
				questions: [
					{
						type: QuestionObject.TYPE_RADIO_THEN_TEXTAREA,
						text: `${area}.timeuse.wasDayUnusual?`,
						buttons: {
							1: `no`,
							2: `${area}.timeuse.yesDueToIllness`,
							3: `${area}.timeuse.yesForHoliday`,
							4: `${area}.timeuse.yesForOtherReasons`,
						},
					},
					{
						type: QuestionObject.TYPE_LARGE_BUTTONS,
						text: `${area}.timeuse.dayMoodRate?`,
						buttons: {
							1: `${area}.timeuse.better`,
							2: `${area}.timeuse.same`,
							3: `${area}.timeuse.worst`,
						},
					},
				],
			},
		],
	}
}
const behaviorsModule = (area, index, accessibility, showCredits=false) => {
	return {
		index: index,
		id: module = `${area}.module${index}`,
		title: `${area}.behaviors.title`,
		type: ModuleTypes.TYPE_BEHAVIORS,
		hasModalEnd: true,
		accessibilityMonths0: accessibility.months0,
		accessibilityMonths1: accessibility.months1,
		accessibilityDays0: accessibility.days0,
		accessibilityDays1: accessibility.days1,
		credits: showCredits ? `${area}.behaviors.credits` : '',
		pages: [
			{
				id: page = `${module}.page1`,
				title: `${area}.behaviors.page1.title`,
				questions: [
					{
						type: QuestionObject.TYPE_LARGE_BUTTONS,
						text: `${area}.behaviors.page1.question1.text`,
						buttons: {
							1: `notYet`,
							2: `sometimes`,
							3: `often`,
						},
					},
					{
						type: QuestionObject.TYPE_LARGE_BUTTONS,
						text: `${area}.behaviors.page1.question2.text`,
						buttons: {
							1: `notYet`,
							2: `sometimes`,
							3: `often`,
						},
					},
					{
						type: QuestionObject.TYPE_LARGE_BUTTONS,
						text: `${area}.behaviors.page1.question3.text`,
						buttons: {
							1: `notYet`,
							2: `sometimes`,
							3: `often`,
						},
					},
				]
			},
			{
				id: page = `${module}.page1`,
				title: `${area}.behaviors.page2.title`,
				questions: [
					{
						type: QuestionObject.TYPE_LARGE_BUTTONS,
						text: `${area}.behaviors.page2.question1.text`,
						buttons: {
							1: `notYet`,
							2: `sometimes`,
							3: `often`,
						},
					},
					{
						type: QuestionObject.TYPE_LARGE_BUTTONS,
						text: `${area}.behaviors.page2.question2.text`,
						buttons: {
							1: `notYet`,
							2: `sometimes`,
							3: `often`,
						},
					},
					{
						type: QuestionObject.TYPE_LARGE_BUTTONS,
						text: `${area}.behaviors.page2.question3.text`,
						buttons: {
							1: `notYet`,
							2: `sometimes`,
							3: `often`,
						},
					},
				]
			},
		],
	}
}
const phrasesModule = (area, index, accessibility, showCredits=false) => {
	return {
		index: index,
		id: module = `${area}.module${index}`,
		title: `${area}.phrases.title`,
		type: ModuleTypes.TYPE_PHRASES,
		hasModalEnd: true,
		accessibilityMonths0: accessibility.months0,
		accessibilityMonths1: accessibility.months1,
		accessibilityDays0: accessibility.days0,
		accessibilityDays1: accessibility.days1,
		credits: showCredits ? `${area}.phrases.credits` : '',
		pages: [
			{
				id: page = `${module}.page1`,
				questions: [
					{
						type: QuestionObject.TYPE_LARGE_BUTTONS,
						text: `${area}.phrases.page1.question1.text`,
						buttons: {
							1: `notYet`,
							2: `sometimes`,
							3: `often`,
						},
					},
				]
			},
			{
				id: page = `${module}.page2`,
				title: `${area}.phrases.page2.title`,
				questions: [
					{
						type: QuestionObject.TYPE_LARGE_BUTTONS,
						text: `chooseAPhrase`,
						buttons: {
							1: `${area}.phrases.page2.question1.answer1`,
							2: `${area}.phrases.page2.question1.answer2`,
						},
					},
					{
						type: QuestionObject.TYPE_LARGE_BUTTONS,
						text: `chooseAPhrase`,
						buttons: {
							1: `${area}.phrases.page2.question2.answer1`,
							2: `${area}.phrases.page2.question2.answer2`,
						},
					},
					{
						type: QuestionObject.TYPE_LARGE_BUTTONS,
						text: `chooseAPhrase`,
						buttons: {
							1: `${area}.phrases.page2.question3.answer1`,
							2: `${area}.phrases.page2.question3.answer2`,
						},
					},
					{
						type: QuestionObject.TYPE_LARGE_BUTTONS,
						text: `chooseAPhrase`,
						buttons: {
							1: `${area}.phrases.page2.question4.answer1`,
							2: `${area}.phrases.page2.question4.answer2`,
						},
					},
					{
						type: QuestionObject.TYPE_LARGE_BUTTONS,
						text: `chooseAPhrase`,
						buttons: {
							1: `${area}.phrases.page2.question5.answer1`,
							2: `${area}.phrases.page2.question5.answer2`,
						},
					},
					{
						type: QuestionObject.TYPE_LARGE_BUTTONS,
						text: `chooseAPhrase`,
						buttons: {
							1: `${area}.phrases.page2.question6.answer1`,
							2: `${area}.phrases.page2.question6.answer2`,
						},
					},
					{
						type: QuestionObject.TYPE_LARGE_BUTTONS,
						text: `chooseAPhrase`,
						buttons: {
							1: `${area}.phrases.page2.question7.answer1`,
							2: `${area}.phrases.page2.question7.answer2`,
						},
					},
					{
						type: QuestionObject.TYPE_LARGE_BUTTONS,
						text: `chooseAPhrase`,
						buttons: {
							1: `${area}.phrases.page2.question8.answer1`,
							2: `${area}.phrases.page2.question8.answer2`,
						},
					},
					{
						type: QuestionObject.TYPE_LARGE_BUTTONS,
						text: `chooseAPhrase`,
						buttons: {
							1: `${area}.phrases.page2.question9.answer1`,
							2: `${area}.phrases.page2.question9.answer2`,
						},
					},
					{
						type: QuestionObject.TYPE_LARGE_BUTTONS,
						text: `chooseAPhrase`,
						buttons: {
							1: `${area}.phrases.page2.question10.answer1`,
							2: `${area}.phrases.page2.question10.answer2`,
						},
					},
					{
						type: QuestionObject.TYPE_LARGE_BUTTONS,
						text: `chooseAPhrase`,
						buttons: {
							1: `${area}.phrases.page2.question11.answer1`,
							2: `${area}.phrases.page2.question11.answer2`,
						},
					},
					{
						type: QuestionObject.TYPE_LARGE_BUTTONS,
						text: `chooseAPhrase`,
						buttons: {
							1: `${area}.phrases.page2.question12.answer1`,
							2: `${area}.phrases.page2.question12.answer2`,
						},
					},
				],
                if:{[`${area}_module${index}_page1_quest1`]: [2, 3]}
			},
			{
				id: page = `${module}.page3`,
				title: `focusOnHisWords`,
				questions: [
					{
						type: QuestionObject.TYPE_TEXTAREA,
						text: `${area}.phrases.page3.question1.text`,
					},
				],
                if:{[`${area}_module${index}_page1_quest1`]: [2, 3]}
			},
			{
				id: page = `${module}.page4`,
				title: `focusOnHisWords`,
				questions: [
					{
						type: QuestionObject.TYPE_LARGE_BUTTONS,
						text: `${area}.phrases.page4.question1.text`,
						buttons: {
							1: `${area}.phrases.page4.question1.answer1`,
							2: `${area}.phrases.page4.question1.answer2`,
							3: `${area}.phrases.page4.question1.answer3`,
						},
					},
				],
                if:{[`${area}_module${index}_page1_quest1`]: [2, 3]}
			},
		],
	}
}
const agesAndStagesModule = (area, index, accessibility, showCredits=false) => {
	return {
		index: index,
		id: module = `${area}.module${index}`,
		title: `${area}.agesAndStages.title`,
		type: ModuleTypes.TYPE_AGES_AND_STAGES,
		hasModalEnd: true,
		accessibilityMonths0: accessibility.months0,
		accessibilityMonths1: accessibility.months1,
		accessibilityDays0: accessibility.days0,
		accessibilityDays1: accessibility.days1,
		credits: showCredits ? `${area}.agesAndStages.credits` : '',
		pages: [
			{
				id: page = `${module}.page1`,
				title: `${area}.agesAndStages.module${index}.page1.title`,
				questions: [
					{
						type: QuestionObject.TYPE_LARGE_BUTTONS,
						text: `${area}.agesAndStages.module${index}.page1.question1.text`,
						buttons: {
							1: `yes`,
							2: `sometimes`,
							3: `notYet`,
						},
					},
					{
						type: QuestionObject.TYPE_LARGE_BUTTONS,
						text: `${area}.agesAndStages.module${index}.page1.question2.text`,
						buttons: {
							1: `yes`,
							2: `sometimes`,
							3: `notYet`,
						},
					},
					{
						type: QuestionObject.TYPE_LARGE_BUTTONS,
						text: `${area}.agesAndStages.module${index}.page1.question3.text`,
						buttons: {
							1: `yes`,
							2: `sometimes`,
							3: `notYet`,
						},
					},
				]
			},
			{
				id: page = `${module}.page2`,
				// title: `answerFollowingQuestions`,
				questions: [
					{
						type: index !== 12 ? QuestionObject.TYPE_LARGE_BUTTONS : QuestionObject.TYPE_RADIO_THEN_TEXTAREA,
						text: `${area}.agesAndStages.module${index}.page2.question1.text`,
						buttons: index !== 12
						? {
								1: `yes`,
								2: `sometimes`,
								3: `notYet`,
							}
						: {
								1: `notYet`,
								2: `${area}.agesAndStages.module4.page3.question1.answer2`,
								3: {
									text: `${area}.agesAndStages.module4.page3.question1.answer3`,
									has: 'textarea'
								},
							},
					},
					{
						type: QuestionObject.TYPE_LARGE_BUTTONS,
						text: `${area}.agesAndStages.module${index}.page2.question2.text`,
						buttons: {
							1: `yes`,
							2: `sometimes`,
							3: `notYet`,
						},
					},
				]
			},
			{
				id: page = `${module}.page3`,
				title: index !== 12 ? "" : `${area}.agesAndStages.module${index}.page3.title`,
				questions: [
					{
						type: index !== 12 ? QuestionObject.TYPE_RADIO_THEN_TEXTAREA : QuestionObject.TYPE_LARGE_BUTTONS,
						text: `${area}.agesAndStages.module${index}.page3.question1.text`,
						buttons: index !== 12
						? {
								1: {
									text: `${area}.agesAndStages.module${index}.page3.question1.answer3`,
									has: 'textarea'
								},
                                2: `${area}.agesAndStages.module${index}.page3.question1.answer2`,
								3: `notYet`,
							}
						: {
							1: 'yes',
							2: 'sometimes',
							3: 'notYet',
						},
					},
				]
			},
		],
	}
}
let area, module, page
export const dataArea2observation = {
	id: area = AreaObject.AREA2_OBSERVATION,
	title: `${area}.title`,
	modules: [
		//parole
		{
			id: module = `${area}.module1`,
			title: `${module}.title`,
			credits: `${module}.credits`,
			type: ModuleTypes.TYPE_WORDS,
			pages: [
				{
					id: page = `${module}.page1`,
					title: `${page}.title`,
					questions: [
					]
				},
			]
		},

		// //comportamenti
		behaviorsModule(area, 2, {
			months0: 23,
			months1: 24,
			days0: 0,
			days1: 30,
		}, true), // showCredits
		//frasi
		phrasesModule(area, 3, {
			months0: 23,
			months1: 24,
			days0: 0,
			days1: 30,
		}, true), // showCredits
		//ages and stages
		agesAndStagesModule(area, 4, {
			months0: 23,
			months1: 24,
			days0: 0,
			days1: 30,
		}, true), // showCredits
		//timeuse
		timeuseModule(area, 5, {
			months0: 23,
			months1: 24,
			days0: 0,
			days1: 30,
		}, true), // showCredits

		//comportamenti
		behaviorsModule(area, 6, {
			months0: 29,
			months1: 30,
			days0: 0,
			days1: 30,
		}),
		//frasi
		phrasesModule(area, 7, {
			months0: 29,
			months1: 30,
			days0: 0,
			days1: 30,
		}),
		//ages and stages
		agesAndStagesModule(area, 8, {
			months0: 29,
			months1: 30,
			days0: 0,
			days1: 30,
		}),
		//timeuse
		timeuseModule(area, 9, {
			months0: 29,
			months1: 30,
			days0: 0,
			days1: 30,
		}),

		//comportamenti
		behaviorsModule(area, 10, {
			months0: 35,
			months1: 36,
			days0: 0,
			days1: 30,
		}),
		//frasi
		phrasesModule(area, 11, {
			months0: 35,
			months1: 36,
			days0: 0,
			days1: 30,
		}),
		//ages and stages
		agesAndStagesModule(area, 12, {
			months0: 35,
			months1: 36,
			days0: 0,
			days1: 30,
		}),
		//timeuse
		timeuseModule(area, 13, {
			months0: 35,
			months1: 36,
			days0: 0,
			days1: 30,
		}),

	],
}

//starts with index 1
/**
 * riempio la struttura categorie e attivita' con gli id, che servono per la lingua
 * esempio:
 * [
 *   {
 *     id: "cat1",
 *     acts: [
 *       {
 *         id: "cat1_act1"
 *       },
 *       {
 *         id: "cat1_act2"
 *         isOther: true,
 *       },
 *     ]
 *   },
 *   {
 *     id: "cat2",
 *     cats: [
 *       {
 *         id: "cat2_cat1",
 *         acts: [
 *           {
 *             id: "cat2_cat1_act1"
 *           },
 *           {
 *             id: "cat2_cat1_act2"
 *             isOther: true,
 *           },
 *         ],
 *       },
 *     ],
 *   },
 * ]
 */
export const timeuseCatsAndActs = (() => {
	const cats = [
		{
			actsCount: 5,
		},
		{
			actsCount: 4,
		},
		{
			actsCount: 8,
		},
		//attivita' coi figli
		{
			cats: [
				{
					actsCount: 4,
				},
				{
					actsCount: 4,
				},
				{
					actsCount: 9,
				},
				{
					actsCount: 7,
				},
				{
					actsCount: 6,
				},
				{
					actsCount: 2,
				},
				{
					actsCount: 3,
				},
				{
					actsCount: 7,
				},
				{
					actsCount: 7,
				},
				{
					actsCount: 3,
				},
			],
		},
		{
			actsCount: 5,
		},
		{
			actsCount: 9,
		},
		{
			actsCount: 4,
		},
		{
			actsCount: 5,
		},
		{
			actsCount: 5,
		},
		{
			actsCount: 9,
		},
		{
			actsCount: 3,
		},
	]

	return cats.map((cat1, c1) => {
		const fillActs = cat => {
			cat.acts = Array(cat.actsCount).fill(null).map((act, a, array) => {
				return {
					id: `${cat.id}_act${++a}`,
					isOther: a === array.length,
				}
			})
			return cat
		}

		cat1.id = `cat${++c1}`

		if(cat1.actsCount) {
			cat1 = fillActs(cat1)
		}
		else {
			cat1.cats = cat1.cats.map((cat2, c2) => {
				cat2.id = `${cat1.id}_cat${++c2}`
				cat2 = fillActs(cat2)
				return cat2
			})
		}

		return cat1
	})
})()



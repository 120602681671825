<script setup>
import {useNavigationStore} from '@/stores/navigation.js';
import {useMainStateStore} from '@/stores/mainState.js';
import {compClass} from '@/libraries/utils.js';
import HamburgerIcon from '@/Pages/sharedComponents/icons/Hamburger.vue';
import XIcon from '@/Pages/sharedComponents/icons/X.vue';
import PointLeftIcon from '@/Pages/sharedComponents/icons/PointLeft.vue';
import ContactsIcon from '@/Pages/sharedComponents/icons/Contacts.vue';
import LanguageIcon from '@/Pages/sharedComponents/icons/Language.vue';
import AboutIcon from '@/Pages/sharedComponents/icons/About.vue';
import LogoutIcon from '@/Pages/sharedComponents/icons/Logout.vue';
import {onMounted, ref} from 'vue';
import {useRoute} from 'vue-router';
import { usePage } from '@inertiajs/vue3';
import MicroModal from 'micromodal';
import ModalSetLanguage from '@/Pages/sharedComponents/modalSetLanguage.vue';
import moment from 'moment/moment';

const currentRoute = useRoute()
// const vueRouter = useRouter()
// console.debug('currentRoute.matched', currentRoute, vueRouter.currentRoute)

const mainStore = useMainStateStore()
const navigationStore = useNavigationStore()

const panelIsVisible = ref(false)
const togglePanelVisibility = () => panelIsVisible.value = !panelIsVisible.value

//nelle pagine interne alle aree la freccia porta alla home dell'area senza richiesta al server
//nel resto delle pagine porta alla home con richiesta al server
const goHome = () => {
	console.log(currentRoute.name)

	if( ![
		'area1personal', 'area2observation', 'area3relations',
		'area4activities', 'area5playroom', 'area6audio',
		'about'
	].includes(currentRoute.name) ) {
		//area1personal_module2_page3 >>> area1personal
		const homeRouteName = currentRoute.name.replace(/_.+$/, '')

		navigationStore.goToPage(homeRouteName)
	}
	else {
		window.location.href = navigationStore.url('/')
	}
}

function getSimulatedAge(){
    return ('SIMULATED AGE: ' + Math.floor(mainStore.apparentChildAgeInMonths) + ' months ' + Math.floor((mainStore.apparentChildAgeInMonths - Math.floor(mainStore.apparentChildAgeInMonths))*31) + ' days')
}

onMounted(()=>{
	const user = usePage().props.user
	mainStore.user.hydrate(user)
	mainStore.childBirthdate = moment(user.child_birthdate)

	MicroModal.init()
})
</script>

<template>
	<div :class="compClass()"
		class="flex justify-between items-center p-5 bg-blue">
		<div class="basis-1/3">
			<div v-if="$route.name !== 'home' && $route.name !== 'welcome'"
				v-show="!panelIsVisible"
				class="pr-6 clickable"
				@click="goHome">
				<PointLeftIcon :color="'white'" class="w-4 h-auto" />
			</div>
		</div>

		<div class="basis-1/3 text-white text-base text-center max-w-[60%]">
            <p>{{ $tg("yourCodeIs") }}</p><p class="text-yellow">{{ mainStore.user.name }}</p>
            <p class="text-sm opacity-50">{{ navigationStore.fakeNow ? '\n'+getSimulatedAge() : '' }}</p>
		</div>

		<div class="basis-1/3 flex justify-end">
			<HamburgerIcon
				v-if="!panelIsVisible"
				:color="'white'" class="w-10 h-auto clickable"
				@click="togglePanelVisibility"
			/>
			<XIcon
				v-if="panelIsVisible"
				:color="'white'" class="w-7 h-auto mr-2 clickable"
				@click="togglePanelVisibility"
			/>
		</div>
	</div>

	<div class="absolute w-full min-h-screen z-10 overflow-hidden bg-white will-change-transform"
		style="transition: transform .3s"
		:style="`transform:translateX(${panelIsVisible ? 0 : 100}%)`">

		<div class="h-full flex justify-center">
			<div class="h-full text-4xl text-blue">
				<div class="mb-8 pb-4 bg-blue bg-fullwidth">
					<img class="w-auto h-28" src="/img/logo.png" alt="app parola">
				</div>

				<a class="flex items-end py-8" :href="navigationStore.url('/about')">
					<AboutIcon :color="'fuchsia'" class="w-12 h-auto"/>
					<div class="pl-6">{{ $tg('about') }}</div>
				</a>
				<a class="flex items-end py-8" href="mailto:psi.progettoparola@unibo.it" target="_blank">
					<ContactsIcon :color="'fuchsia'" class="w-12 h-auto"/>
					<div class="pl-6">{{ $tg('contacts') }}</div>
				</a>
				<a class="flex items-end py-8" href="javascript:void(0)"
					@click="() => MicroModal.show('modal-setLanguage')">
					{{/*language.svg non funziona bene, uso png*/''}}
                    <LanguageIcon :color="'fuchsia'" class="w-12 h-auto"/>
					<div class="pl-6">{{ $tg('language') }}</div>
				</a>
				<a class="flex items-end py-8" :href="navigationStore.url('/logout')">
					<LogoutIcon :color="'fuchsia'" class="w-12 h-auto"/>
					<div class="pl-6 relative w-full">
						{{ $tg('logout') }}
						<div class="absolute right-0 top-0 text-sm text-right" v-if="mainStore.environment === 'local'">
							{{mainStore.user.name}}
						</div>
					</div>
				</a>
			</div>
		</div>
	</div>

	<ModalSetLanguage />
</template>
